.newProduct {
  flex: 4;
}

.newProduct input{
  margin: 10px 0px;
  padding: 10px;
  width: 350px;
}

.newProduct textarea{
  padding: 30px;
}

.addProductForm {
  margin-top: 10px;
 
}

.addProductItem {
  width: 250px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.addProductItem > label {
  color: gray;
  font-weight: 600;
  margin-bottom: 10px;
}

.addProductItem > input {
  padding: 10px;
  width: 200px;
}

.addProductItem > select {
  padding: 10px;
}

.addProductButton {
  margin-top: 10px;
  padding: 7px 10px;
  border: none;
  border-radius: 10px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.date-remove{
  background-color: red;
  padding: 5px;
  cursor: pointer;
  outline: none;
  border: none;
  color: #fff;
  margin-left: 10px;

}

.selected-date{
  margin: 10px 0px;
}
