.productList{
    flex: 4;
    position: relative;
}

.productListItem{
    display: flex;
    align-items: center;
}
.filters{
  margin: 20px;
  display: flex;
  align-items: center;
}

.filter-header{
  margin: 0px 20px;
}

.filters input{
border-bottom: 1px solid #555;
padding: 10px;
margin: 0px 10px;
outline: none;

}

.filters button{
    padding: 10px;
    margin: 0px 20px;
    border: none;
    background-color: teal;
    color: white;
    cursor: pointer;
    font-size: 15px;
    width: 150px;
}

.productListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

.productListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.productListDelete{
    color: red;
    cursor: pointer;
}


.modal{
    position: absolute;
    top: 100px;
    right: 0;
    height: 60%;
    width: 60%;
    background-color: #fff;
    z-index: 999;
    display: flex;
    flex-direction: column;
  }
  
  .modal-header{
    display: flex;
    margin: 20px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 900;
  }
  
  .cancel-delete{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
  
  .cancel-delete button{
    margin: 20px;
    border: none;
    background-color: teal;
    color: #fff;
    cursor: pointer;
    padding: 10px;
    width: 100px;
  }
  
  .cancel-delete button:nth-child(2){
    margin: 20px;
    border: none;
    background-color: red;
    color: #fff;
    cursor: pointer;
    padding: 10px;
    width: 100px;
  }

  .generatepdf{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 20px;
    padding: 10px;
    border: none;
    background-color: teal;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 15px;
  }

  .new-shift{
    background-color: teal;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }