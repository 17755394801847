.userList {
    flex: 4;
    position: relative;
  }
  
  .userListUser {
    display: flex;
    align-items: center;
  }
  
  .userListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .userListEdit{
      border: none;
      border-radius: 10px;
      padding: 5px 10px;
      background-color: #3bb077;
      color: white;
      cursor: pointer;
      margin-right: 20px;
  }
  
  .userListDelete{
      color: red;
      cursor: pointer;
  }

  .modal{
    position: absolute;
    top: 100px;
    right: 0;
    height: 60%;
    width: 60%;
    background-color: #fff;
    z-index: 999;
    display: flex;
    flex-direction: column;
  }

  .modal-header{
    display: flex;
    margin: 20px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 900;
  }

  .cancel-delete{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }

  .cancel-delete button{
    margin: 20px;
    border: none;
    background-color: teal;
    color: #fff;
    cursor: pointer;
    padding: 10px;
    width: 100px;
  }

  .cancel-delete button:nth-child(2){
    margin: 20px;
    border: none;
    background-color: red;
    color: #fff;
    cursor: pointer;
    padding: 10px;
    width: 100px;
  }